<template>
  <section class="bg-gray-50 dark:bg-gray-900">
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-6">

    <LogoSection />


      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                  Sign in to your account
              </h1>
          <!-- Display validation or success/error messages -->
          <div v-if="alert.message" :class="alert.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'" class="p-4 mb-4 rounded-lg">
            <strong>{{ alert.title }}</strong>
            <p>{{ alert.message }}</p>
          </div>
             
              <form @submit.prevent="login" class="space-y-4 md:space-y-6">
                  <input type="hidden" name="remember" value="true" />
                  <div>
                      <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                      <input 
                      v-model="form.email"
                      id="email"
                      name="email"
                      type="email"
                      required
                      class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Email address">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input 
                      v-model="form.password"
                      id="password"
                      name="password"
                      type="password"
                      required
                        placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
                  </div>
                  <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input 
                              id="remember" 
                              aria-describedby="remember" 
                              name="remember_me"
                              type="checkbox"
                              class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" >
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remember" class="text-gray-500 dark:text-gray-300">Remember me</label>
                          </div>
                      </div>
                      <router-link to="/forgotpassword" class="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                        Forgot password?
                      </router-link>                     

                  </div>
                  <button 
                    type="submit" 
                    :disabled="isSubmitting"
                    class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 disabled:bg-gray-400">
                    <span v-if="!isSubmitting">Sign in</span>
                    <span v-else class="flex items-center gap-2">
                      <svg 
                        class="animate-spin h-5 w-5 text-white" 
                        xmlns="http://www.w3.org/2000/svg" 
                        fill="none" 
                        viewBox="0 0 24 24"
                      >
                        <circle 
                          class="opacity-25" 
                          cx="12" 
                          cy="12" 
                          r="10" 
                          stroke="currentColor" 
                          stroke-width="4"
                        ></circle>
                        <path 
                          class="opacity-75" 
                          fill="currentColor" 
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121-2.122a6 6 0 001.415 1.414L6 18.415l-2-2 2-1.124z"
                        ></path>
                      </svg>
                      Signing in...
                    </span>
                  </button>
                  <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                      Don’t have an account yet?
                      <router-link to="/register" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                        Sign Up
                      </router-link>                      
                  </p>
              </form>
          </div>
      </div>
  </div>
</section>


</template>

<script>
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import LogoSection from '../../components/LogoSection.vue';

export default {
  name: 'LoginPage',
  data() {
    return {
      form: {
        email: '',
        password: ''
      },
      alert: {
        type: '', // 'success' or 'error'
        title: '',
        message: ''
      },     
      csrfToken: '', // Store the CSRF token
      isSubmitting: false
    };
  },
  /*async created() {
    try {
      // Fetch the CSRF token from the Laravel API
      const response = await axios.get(`${API_BASE_URL}/csrf-token`);
      this.csrfToken = response.data.csrf_token;
      console.log(response.data);
    } catch (error) {
      console.error('Failed to retrieve CSRF token:', error);
    }
  },*/
  methods: {
    async login() {
    if (this.isSubmitting) return; // Prevent multiple submissions
    this.isSubmitting = true; // Activate loading state
      try {
            
                const response = await axios.post(`${API_BASE_URL}/login`, this.form);
                localStorage.setItem('token', response.data.token);
                console.log(response.data.token);
                // Login success
                this.alert = {
                  type: 'success',
                  title: 'Success!',
                  message: 'Login successful. Redirecting to dashboard.'
                };       
                this.$router.push('/dashboard');
         
      } catch (error) {
        // Login failure
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorMessages = error.response.data.error || 'Please try again.';
        // Check if there are specific validation errors and append them
        if (error.response.data.errors) {
          errorMessages += '\n' + Object.values(error.response.data.errors).flat().join('\n');
        }        
          this.alert = {
            type: 'error',
            title: 'Error!',
            message: errorMessages
          };
          console.error('API error:', error.response.data);
          console.error('API error stringified:', JSON.stringify(error.response.data, null, 2)); // Beautified output
        } else if (error.request) {
          // The request was made but no response was received
          this.alert = {
            type: 'error',
            title: 'Error!',
            message: 'No response from the server. Please try again later.'
          };
          console.error('No response received:', error.request);
          console.error('API error stringified:', JSON.stringify(error.request, null, 2));
        } else {
          // Something happened in setting up the request that triggered an Error
          this.alert = {
            type: 'error',
            title: 'Unexpected Error!',
            message: 'An unexpected error occurred. Please try again.'
          };
          console.error('Error', error.message);
        }
      } finally {
      this.isSubmitting = false; // Reset loading state
      }
    }
  },
  components: {
    LogoSection,
  },
}
</script>
