<template>
  <section class="bg-gray-50 dark:bg-gray-900 h-screen">
    <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-6">
      <LogoSection />

      <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
        <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
          <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Create an account
          </h1>

          <!-- Display validation or success/error messages -->
          <div v-if="alert.message" :class="alert.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'" class="p-4 mb-4 rounded-lg">
            <strong>{{ alert.title }}</strong>
            <p>{{ alert.message }}</p>
          </div>



          <form @submit.prevent="register" class="space-y-4 md:space-y-6">
            
            <div>
              <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
              <input v-model="form.email" type="email" placeholder="Email" required id="email" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
          
            </div>
            <div>
              <label for="firstname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">First Name</label>
              <input v-model="form.first_name" type="text" placeholder="John" required id="firstname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </div>
            <div>
              <label for="lastname" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Last Name</label>
              <input v-model="form.last_name" type="text" placeholder="Doe" required id="lastname" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </div>    
            <div>
              <label for="phone_number" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone Number</label>
              <input v-model="form.phone_number" type="text" placeholder="+25472000000" required id="phone_number" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">     
            </div> 
            <div>
              <label for="national_id" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">National ID</label>
              <input v-model="form.national_id" type="text" placeholder="111222333" required id="national_id" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            </div>                                                                 
            <div>
              <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
              <input v-model="form.password" type="password" required  id="password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
            </div>
            <div>
              <label for="confirm-password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
              <input v-model="form.password_confirmation" type="password" id="confirm-password" placeholder="••••••••" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" >
            </div>
            <div class="flex items-start">
              <div class="flex items-center h-5">
                <input id="terms" aria-describedby="terms" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
              </div>
              <div class="ml-3 text-sm">
                <label for="terms" class="font-light text-gray-500 dark:text-gray-300">I accept the <a class="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">Terms and Conditions</a></label>
              </div>
            </div>
            <button 
              type="submit" 
              :disabled="isSubmitting"
              class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 disabled:bg-gray-400">

              <span v-if="!isSubmitting">Create an account</span>
              <span v-else class="flex items-center gap-2">
                <svg 
                  class="animate-spin h-5 w-5 text-white" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24"
                >
                  <circle 
                    class="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    stroke-width="4"
                  ></circle>
                  <path 
                    class="opacity-75" 
                    fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291l2.121-2.122a6 6 0 001.415 1.414L6 18.415l-2-2 2-1.124z"
                  ></path>
                </svg>
                Creating...
              </span>             
            </button>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Already have an account? 
              <router-link to="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                Login here
              </router-link>   
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

import { API_BASE_URL } from '../../config';
import LogoSection from '../../components/LogoSection.vue';

export default {
  name : 'RegisterPage',
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        national_id: '',
        password: '',
        password_confirmation: ''
      },
      alert: {
        type: '', // 'success' or 'error'
        title: '',
        message: ''
      },
      csrfToken: '', // Store the CSRF token
      isSubmitting: false     
    };
  },
  async created() {
    try {
      // Fetch the CSRF token from the Laravel API
      const response = await axios.get(`${API_BASE_URL}/csrf-token`);
      this.csrfToken = response.data.csrf_token;
    } catch (error) {
      console.error('Failed to retrieve CSRF token:', error);
    }
  },
  methods: {
    async register() {
    if (this.isSubmitting) return; // Prevent multiple submissions
    this.isSubmitting = true; // Activate loading state     
      try {
        const response = await axios.post(`${API_BASE_URL}/register`, this.form);
        console.log('Registration successful:', response.data);
        // Registration success
        this.alert = {
          type: 'success',
          title: 'Success!',
          message: 'Registration successful. Redirecting to login page.'
        };
        this.$router.push('/login'); // Redirect to login after successful registration
      } catch (error) {
        // Registration failure
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          let errorMessages = error.response.data.message || 'Please try again.';
        // Check if there are specific validation errors and append them
        if (error.response.data.errors) {
          errorMessages += '\n' + Object.values(error.response.data.errors).flat().join('\n');
        }        
          this.alert = {
            type: 'error',
            title: 'Error!',
            message: errorMessages
          };
          console.error('API error:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          this.alert = {
            type: 'error',
            title: 'Error!',
            message: 'No response from the server. Please try again later.'
          };
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          this.alert = {
            type: 'error',
            title: 'Unexpected Error!',
            message: 'An unexpected error occurred. Please try again.'
          };
          console.error('Error', error.message);
        }
      }  finally {
        this.isSubmitting = false; // Reset loading state
      }
    }
  },
  components: {
    LogoSection
  },
};
</script>


