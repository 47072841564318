<template>
    <section class="bg-gray-50 dark:bg-gray-900">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        
        <LogoSection />
  
        <div class="w-full bg-white rounded-lg shadow dark:border sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot your password?
            </h1>
            <p class="text-sm font-light text-gray-500 dark:text-gray-400">
              Enter your email address to receive a password reset link.
            </p>
          <!-- Alert Message -->
          <div v-if="alert" :class="`p-4 mb-4 text-sm rounded-lg ${alert.type === 'error' ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`" role="alert">
            <strong>{{ alert.title }}</strong> {{ alert.message }}
          </div>
            <form @submit.prevent="submitForgotPassword" class="space-y-4 md:space-y-6">
              <div>
                <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Your email
                </label>
                <input 
                  v-model="email"
                  id="email"
                  name="email"
                  type="email"
                  required
                  class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email address"
                >
              </div>
              <button 
                type="submit"
                class="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Send reset link
              </button>
              <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                Remembered your password?
                <router-link to="/login" class="font-medium text-primary-600 hover:underline dark:text-primary-500">
                  Sign in
                </router-link>                      
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import axios from 'axios';
  import { API_BASE_URL } from '../../config';
  import LogoSection from '../../components/LogoSection.vue';
  
  export default {
    name: 'ForgotPasswordPage',
    data() {
      return {
        email: '',
        alert: null,
        csrfToken: '', // Store the CSRF token
      };
    },
  async created() {
    try {
      // Fetch the CSRF token from the Laravel API
      const response = await axios.get(`${API_BASE_URL}/csrf-token`);
      this.csrfToken = response.data.csrf_token;
    } catch (error) {
      console.error('Failed to retrieve CSRF token:', error);
    }
  },
    methods: {
      async submitForgotPassword() {
        try {
          const response = await axios.post(`${API_BASE_URL}/forgot-password`, { email: this.email });
          console.log('Password reset link sent:', response.data);
          
          // Success message
          this.alert = {
            type: 'success',
            title: 'Success!',
            message: 'A password reset link has been sent to your email.'
          };
  
          // Optionally redirect to login or clear the form
          this.email = '';
        } catch (error) {
          if (error.response) {
            // Server responded with a status other than 2xx
            let errorMessages = error.response.data.message || 'Please try again.';
            
            // Check for specific validation errors
            if (error.response.data.errors) {
              errorMessages += '\n' + Object.values(error.response.data.errors).flat().join('\n');
            }
  
            this.alert = {
              type: 'error',
              title: 'Error!',
              message: errorMessages
            };
            console.error('API error:', error.response.data);
          } else if (error.request) {
            // No response received
            this.alert = {
              type: 'error',
              title: 'Error!',
              message: 'No response from the server. Please try again later.'
            };
            console.error('No response received:', error.request);
          } else {
            // Unexpected error
            this.alert = {
              type: 'error',
              title: 'Unexpected Error!',
              message: 'An unexpected error occurred. Please try again.'
            };
            console.error('Error', error.message);
          }
        }
      }
    },
    components: {
      LogoSection,
    },
  }
  </script>
  
  
  <style scoped>
  /* Additional custom styling (if needed) */
  </style>
  